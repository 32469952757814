.logo-container {
    z-index: 0;
    width: 800px;
    height: 1200px;
    position: absolute;
    top: 0;
    right: 5%;
    bottom: 0;
    left: auto;
    margin: auto;
    .solid-logo {
      position: absolute;
      top: auto;
      right: auto;
      bottom: auto;
      left: 0;
      margin: auto;
      width: 100%;
      opacity: 0;
      z-index: 1;
      animation: fadeIn 2s 2s forwards;
    }
  }
  
  .svg-container {
    stroke: #ffd700;
    stroke-width: 10;
  }